export async function clearError(web3, error) {
	//console.log(error);
	const isJSON = error.indexOf('{');
	if(isJSON === -1) {
		if(error.indexOf('insufficient funds') > -1) {
			return 'Insufficient funds';
		}
		return error;
	}
	
	const json = JSON.parse(error.substring(isJSON));
	if(json.originalError) {
		const message = json.originalError.message.split(':');
		if(message.length > 1) {
			return message[1];
		}
		return message[0];
	}
	
	return 'Unknown Error';
}
