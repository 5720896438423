export default {
	isMetamask: (state) => state.isMetamask,
	isOwner: (state) => state.isOwner,
	account: (state) => state.account,
	balance: (state) => state.balance,
	network: (state) => state.network,
	networkId: (state) => state.networkId,
	contractAddress: (state) => state.contractAddress,
	contractBalance: (state) => state.contractBalance,
	errorMessage: (state) => state.errorMessage,
	infoMessage: (state) => state.infoMessage,
	etherscan: (state) => state.etherscan,
	/////////////////////////
	players: (state) => state.players,
	lastWinner: (state) => state.lastWinner,
	lastAmount: (state) => state.lastAmount,
};
