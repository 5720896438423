const contractLottery = {
	"info": {
		"date": "01/06/2022, 17:40:28",
		"contract": "0x6f9e5f46C6178F9A3e2324C422BD39A1057749DF",
		"owner": "0x231c681893d861b04CC1A29624077c39bbFB7Aab",
		"network": "rinkeby",
		"networkId": 4
	},
	"abi": [{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor",
		"signature": "constructor"
	}, {
		"inputs": [],
		"name": "DEV_TAX_PERCENT",
		"outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
		"stateMutability": "view",
		"type": "function",
		"constant": true,
		"signature": "0x06c9a517"
	}, {
		"inputs": [],
		"name": "MIN_PLAYERS",
		"outputs": [{"internalType": "uint8", "name": "", "type": "uint8"}],
		"stateMutability": "view",
		"type": "function",
		"constant": true,
		"signature": "0x85f07bbe"
	}, {
		"inputs": [],
		"name": "MIN_WEI_PRICE",
		"outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
		"stateMutability": "view",
		"type": "function",
		"constant": true,
		"signature": "0xb3a25031"
	}, {
		"inputs": [],
		"name": "enter",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function",
		"payable": true,
		"signature": "0xe97dcb62"
	}, {
		"inputs": [],
		"name": "getPlayers",
		"outputs": [{"internalType": "address payable[]", "name": "", "type": "address[]"}],
		"stateMutability": "view",
		"type": "function",
		"constant": true,
		"signature": "0x8b5b9ccc"
	}, {
		"inputs": [],
		"name": "lastAmount",
		"outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
		"stateMutability": "view",
		"type": "function",
		"constant": true,
		"signature": "0x829a86d9"
	}, {
		"inputs": [],
		"name": "lastWinner",
		"outputs": [{"internalType": "address", "name": "", "type": "address"}],
		"stateMutability": "view",
		"type": "function",
		"constant": true,
		"signature": "0xfe188184"
	}, {
		"inputs": [],
		"name": "owner",
		"outputs": [{"internalType": "address payable", "name": "", "type": "address"}],
		"stateMutability": "view",
		"type": "function",
		"constant": true,
		"signature": "0x8da5cb5b"
	}]
}

export default function createLotteryContract(web3) {
	return new web3.eth.Contract(contractLottery.abi, contractLottery.info.contract);
}
