<template>
  <div class="container py-3">
    <div class="row">
      <div class="col-12 text-center">
        This is only a test application for educational purposes. These are not real ETH and not real money.
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center small">
        <a href="https://adrianp.pl/" target="_blank"
           class="font-weight-bold">adrianp.pl</a> 2022
        . Contract address:
        <a :href="etherscan + 'address/' + contractAddress"
           class="font-weight-bold" target="_blank">
          {{ contractAddress }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: 'AppFooter',
  computed: {
    ...mapGetters(['etherscan', 'contractAddress']),
  }
}
</script>
