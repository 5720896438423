<template>
  <main v-cloak>
    <div v-if='isMetamask === 1 && account && account.length > 0'>
      <div v-if='network === "rinkeby"'>
        <AppHeader></AppHeader>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <AppContent></AppContent>
            </div>
          </div>
        </div>
        <AppFooter></AppFooter>
      </div>
      <div v-else>
        <div class="container">
          <div class="row">
            <div class="col-12 text-center p-5">
              <h3 class="font-weight-normal">
                <strong>Wrong network!</strong> Please, use <strong>Rinkeby Test
                Network</strong>.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isMetamask === 2" class="container">
      <div class="row">
        <div class="col-12 text-center p-5">
          <h3 class="font-weight-normal">
            Please, install <strong>MetaMask</strong> extension for your browser and refresh the page!
          </h3>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import AppHeader from "@/components/AppHeader";
import AppContent from "@/components/AppContent";
import AppFooter from "@/components/AppFooter";

export default {
  name: 'App',
  components: {
    AppFooter,
    AppContent,
    AppHeader
  },
  mounted() {
    this.$store.dispatch('initWeb3');
  },
  computed: /* properties, reactive and readonly */ {
    ...mapGetters([
      'isMetamask',
      'isOwner',
      'account',
      'balance',
      'network',
      'networkId',
    ]),
  },
  data() {
    return {
      message: 'test aaa',
    };
  },
};
</script>

<style>
[v-cloak] {
  display: none;
}
</style>
