import createLotteryContract from "@/contracts/LotteryAbi";
import * as Utils from "@/store/utils";

const Web3 = require('web3');
let web3;
let lotteryContract;
let appInterval;

export default {
	async initWeb3({ /*state,*/ commit, dispatch}) {
		if(window.ethereum) {
			web3 = new Web3(window.ethereum);
			try {
				// enable web3
				await window.ethereum.request({
					method: 'eth_requestAccounts',
				});
				//console.log(accounts);
				commit('updateIsMetamask', 1);
				
				// detect Account change
				window.ethereum.on('accountsChanged', async function (accounts) {
					console.log('accountsChanges', accounts);
					dispatch('refreshAccountData');
				});
				
				// detect Network change
				window.ethereum.on('chainChanged', async function (networkId) {
					console.log('chainChanged', networkId);
					dispatch('refreshAccountData');
				});
				// Contracts
				lotteryContract = createLotteryContract(web3);
				commit('updateContractAddress', lotteryContract.options.address);
				///////////////////////////////////////////////////////
				dispatch('refreshAccountData');
			} catch (error) {
				if(error.code === 4001) {
					// User rejected request
				}
				commit('updateIsMetamask', 2);
			}
		} else {
			commit('updateIsMetamask', 2);
		}
	},
	async refreshAccountData({commit, dispatch}) {
		// network id
		let networkId = await web3.eth.net.getId();
		commit('updateNetworkId', networkId);
		// network
		const network = await web3.eth.net.getNetworkType();
		commit('updateNetwork', network);
		// account address
		const accounts = await web3.eth.getAccounts();
		console.log('Accounts: ', accounts);
		const activeAccount = accounts[0];
		commit('updateAccount', activeAccount);
		
		dispatch('refreshBalance');
		dispatch('isOwner');
		dispatch('setErrorMessage', '');
		dispatch('setInfoMessage', '');
		
		function mainLoop() {
			dispatch('refreshContractBalance');
			dispatch('refreshPlayers');
			dispatch('refreshLastWinner');
			dispatch('refreshLastAmount');
			//console.log('app refresh');
		}
		
		mainLoop();
		if(appInterval) clearInterval(appInterval);
		appInterval = setInterval(() => {
			mainLoop();
		}, 3000);
	},
	async refreshBalance({commit, state}) {
		// balance
		const activeBalance = await web3.eth.getBalance(state.account);
		if(activeBalance !== state.balance) {
			commit('updateBalance', web3.utils.fromWei(activeBalance, 'ether'));
		}
	},
	async isOwner({state, commit}) {
		const ownerAddress = await lotteryContract.methods.owner().call();
		if(ownerAddress === state.account) {
			commit('updateIsOwner', true);
		} else {
			commit('updateIsOwner', false);
		}
	},
	setErrorMessage({commit, dispatch}, value) {
		commit('updateErrorMessage', value);
		if(value.length > 0) {
			dispatch('setInfoMessage', '');
		}
	},
	setInfoMessage({commit, dispatch}, value) {
		commit('updateInfoMessage', value);
		if(value.length > 0) {
			dispatch('setErrorMessage', '');
		}
	},
	//////////////////////////////////////////////////////////////
	async refreshContractBalance({state, commit, dispatch}) {
		try {
			const balance = await web3.eth.getBalance(state.contractAddress);
			commit('updateContractBalance', web3.utils.fromWei(balance, 'ether'));
		} catch (e) {
			dispatch('setErrorMessage', e);
		}
	},
	async refreshPlayers({commit, dispatch}) {
		try {
			const players = await lotteryContract.methods.getPlayers().call();
			commit('updatePlayers', players);
		} catch (e) {
			dispatch('setErrorMessage', e);
		}
	},
	async refreshLastWinner({commit, dispatch}) {
		try {
			const lastWinner = await lotteryContract.methods.lastWinner().call();
			commit('updateLastWinner', lastWinner);
		} catch (e) {
			dispatch('setErrorMessage', e);
		}
	},
	async refreshLastAmount({commit, dispatch}) {
		try {
			const lastAmount = await lotteryContract.methods.lastAmount().call();
			commit('updateLastAmount', web3.utils.fromWei(lastAmount, 'ether'));
		} catch (e) {
			dispatch('setErrorMessage', e);
		}
	},
	async enterLottery({state, dispatch}, value) {
		const params = {
			from: state.account,
			value: web3.utils.toWei('' + value, 'ether'),
			gas: 1000000,
		};
		try {
			dispatch('setInfoMessage', `Waiting for transaction success...`);
			await lotteryContract.methods.enter().call(params);
			const tx = await lotteryContract.methods.enter().send(params);
			dispatch('setInfoMessage', `<strong>Success.</strong> Transaction hash: <a href="${state.etherscan + 'tx/' + tx.transactionHash}" target="_blank">${tx.transactionHash}</a>`)
		} catch (e) {
			dispatch('setErrorMessage', await Utils.clearError(web3, e.message));
		}
		
		dispatch('refreshBalance');
	}
};
