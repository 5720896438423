<template>
  <header v-if="account" class="mb-5">
    <nav class="navbar navbar-dark bg-dark navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="/">
        Lottery <span v-if="isOwner" class="small">(owner)</span>
      </a>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
      </div>
      <p class="navbar-nav ml-auto">
        <span class="badge badge-success mb-2 mb-md-2 mb-lg-0 mb-xl-0">
          {{ network }},
          0x...{{ account.substr(-4) }}
        </span>
        <span class="badge badge-info ml-2">
          {{ parseFloat(balance).toFixed(8) }} ETH
        </span>
      </p>
    </nav>
  </header>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: 'AppHeader',
  computed: {
    ...mapGetters([
      'isMetamask',
      'isOwner',
      'account',
      'balance',
      'network',
      'networkId',
    ]),
  },
}
</script>
