export default {
	isMetamask: 0,
	isOwner: false,
	account: null,
	balance: null,
	network: null,
	networkId: null,
	contractAddress: null,
	contractBalance: 0,
	errorMessage: '',
	infoMessage: '',
	etherscan: 'https://rinkeby.etherscan.io/',
	///////////////////////
	players: [],
	lastWinner: null,
	lastAmount: null,
};
