<template>
  <div class="">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title">Lottery</h3>
        <hr>
        <p class="card-text">
          Insert amount (min. {{ minValue }}) of ETH and click Enter button to participate. If you don't have ETH,
          please use <a
            href="https://faucets.chain.link/rinkeby" class="font-weight-bold" target="_blank">Faucet</a> to add some
          ETH to your wallet.
        </p>
        <form>
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">ETH</div>
              </div>
              <input type="number" class="form-control" id="inlineFormInputGroup" placeholder="value"
                     :min="minValue" :step="minValue" v-model.number.trim="amount"
              >
            </div>
          </div>
          <div class="form-group">
            <button @click.prevent="enter()" class="btn btn-success w-100">
              Enter
            </button>
          </div>
        </form>
        <div v-if="errorMessage.length" class="small text-danger text-center" v-html="errorMessage"></div>
        <div v-if="infoMessage.length" class="small text-success text-center" v-html="infoMessage"></div>
      </div>
    </div>
    <h1 class="p-3 text-center">
      <small>Win</small> {{ contractBalance }} ETH <br>
      <small style="margin-top: 0.7rem;font-size: 1rem; line-height: 1.2rem;display: block;">Latest winner address:
        <br> 0x...{{ lastWinner ? lastWinner.substr(-4) : '' }} <strong>({{ lastAmount  }} ETH)</strong></small>
    </h1>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title text-center">Lottery participants (max. {{ maxParticipants }})</h4>
        <p class="text-center small">Last participant will trigger 'pick winner'. Contract balance will be transferred
          to the winner. There is 1% dev fee :)</p>
        <hr>
        <ul class="list-unstyled text-center">
          <li v-for="(value,index) in players" :key="index">0x...{{ value.substr(-4) }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: 'AppContent',
  setup() {
    return {
      minValue: '0.01',
      maxParticipants: 3,
    }
  },
  data() {
    return {
      amount: this.minValue,
    }
  },
  computed: {
    ...mapGetters(['errorMessage', 'infoMessage', 'players', 'lastWinner', 'lastAmount', 'contractBalance']),
  },
  methods: {
    enter() {
      if(this.amount < this.minValue) {
        this.$store.dispatch('setErrorMessage', `Amount of ETH is to small, min. ${this.minValue} ETH`);
        return;
      }
      this.$store.dispatch('setErrorMessage', '');
      this.$store.dispatch('enterLottery', this.amount);
    },
  }
}
</script>
