import { createStore } from 'vuex';
import actions from '@/store/actions';
import state from '@/store/state';
import getters from '@/store/getters';
import mutations from '@/store/mutations';

export default createStore({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {},
});
