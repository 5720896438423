export default {
	updateIsMetamask(state, value) {
		state.isMetamask = value;
	},
	updateIsOwner(state, value) {
		state.isOwner = value;
	},
	updateAccount(state, value) {
		state.account = value;
	},
	updateBalance(state, value) {
		state.balance = value;
	},
	updateNetwork(state, value) {
		state.network = value;
	},
	updateNetworkId(state, value) {
		state.networkId = value;
	},
	updateContractAddress(state, value) {
		state.contractAddress = value;
	},
	updateContractBalance(state, value) {
		state.contractBalance = value;
	},
	updateErrorMessage(state, value) {
		state.errorMessage = value;
	},
	updateInfoMessage(state, value) {
		state.infoMessage = value;
	},
	//////////////////////////////////////////////////
	updatePlayers(state, value) {
		state.players = value;
	},
	updateLastWinner(state, value) {
		state.lastWinner = value;
	},
	updateLastAmount(state, value) {
		state.lastAmount = value;
	}
};
